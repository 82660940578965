import React, { PropsWithChildren, ReactElement, useEffect, useId } from "react";
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  useForm,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";

/**
 * Form object with a given schema as defined by yup and default values.
 */
interface IFormProps<T extends FieldValues = FieldValues> {
  onSubmit: (data: T, event?: React.BaseSyntheticEvent, methods?: UseFormReturn<T>) => unknown | Promise<unknown>;
  onInvalidSubmit?: SubmitErrorHandler<T>;
  id?: string;
  resetOnDefaultValuesUpdated?: boolean;
}

export type FormProps<T extends FieldValues = FieldValues> = PropsWithChildren<IFormProps<T> & UseFormProps<T>>;

export default function Form<T extends FieldValues = FieldValues>({
  defaultValues,
  mode,
  resolver,
  children,
  onSubmit,
  onInvalidSubmit,
  reValidateMode,
  shouldFocusError,
  id,
  resetOnDefaultValuesUpdated,
}: FormProps<T>) {
  const methods = useForm({ defaultValues, resolver, mode, reValidateMode, shouldFocusError });
  const { handleSubmit, reset } = methods;
  const submit = handleSubmit(
    (data, event) => onSubmit(data, event, methods),
    (errors, event) => onInvalidSubmit?.(errors, event),
  );
  const formId = useId();

  // Set new default values on update if requested
  useEffect(() => {
    if (resetOnDefaultValuesUpdated && defaultValues) {
      reset(defaultValues as DefaultValues<T>);
    }
  }, [defaultValues, reset, resetOnDefaultValuesUpdated]);

  return (
    <FormProvider {...methods}>
      <form
        id={id || formId}
        onSubmit={(e) => {
          //prevent child form trigger submit of parent form
          e.stopPropagation();
          e.preventDefault();
          return submit(e);
        }}
      >
        {React.Children.map(children as ReactElement[], (child) => {
          return child?.props.name
            ? React.createElement(child.type, {
                ...{
                  ...child.props,
                  register: methods.register,
                  control: methods.control,
                  trigger: methods.trigger,
                  key: child.props.name,
                },
              })
            : child;
        })}
      </form>
    </FormProvider>
  );
}
