const MESSAGE_NAME_PREFIX = "@skipify";
export const MESSAGE_NAMES = {
  // Outbound
  INIT: `${MESSAGE_NAME_PREFIX}/init`, // deprecated
  LISTENER_READY: `${MESSAGE_NAME_PREFIX}/listener-ready`,
  GET_ENROLLMENT_INFO: `${MESSAGE_NAME_PREFIX}/get-enrollment-info`,
  CLOSE_IFRAME: `${MESSAGE_NAME_PREFIX}/close-iframe`,
  GET_RETURNING_USER_INFO: `${MESSAGE_NAME_PREFIX}/get-returning-user-info`,
  DISPLAY_IFRAME: `${MESSAGE_NAME_PREFIX}/display-iframe`,
  ENROLLMENT_ELIGIBLE: `${MESSAGE_NAME_PREFIX}/enrollment-eligible`,
  LOOKUP_ERROR: `${MESSAGE_NAME_PREFIX}/lookup-error`,
  ORDER_COMPLETED: `${MESSAGE_NAME_PREFIX}/order-completed`,
  CREATE_ORDER_ERROR: `${MESSAGE_NAME_PREFIX}/create-order-error`,
  DEVICE_ID: `${MESSAGE_NAME_PREFIX}/device-id`,
  CUSTOMER_EXISTS: `${MESSAGE_NAME_PREFIX}/customer-exists`,
  ASK_FOR_ORDER_ID: `${MESSAGE_NAME_PREFIX}/request-order-id`,
  RESUMABLE_ORDER_ID: `${MESSAGE_NAME_PREFIX}/resumable-order-id`,
  ORDER_ID: `${MESSAGE_NAME_PREFIX}/order-id`,
  CLEAR_ORDER: `${MESSAGE_NAME_PREFIX}/clear-order`,
  SEND_LD_FLAGS: `${MESSAGE_NAME_PREFIX}/send-ld-flags`,
  CHECKOUT_EXITED_BEFORE_ORDER_COMPLETE: `${MESSAGE_NAME_PREFIX}/checkout-exited-before-order-complete`,
  PAYMENT_ERROR_COMPLETE_CHECKOUT: `${MESSAGE_NAME_PREFIX}/payment-error-complete-checkout`,
  LOOKUP_BY_FINGERPRINT_RESULT: `${MESSAGE_NAME_PREFIX}/lookup-by-fingerprint-result`,
  // Inbound
  RECEIVE_ORDER_ID: `${MESSAGE_NAME_PREFIX}/receive-order-id`,
  ENROLLMENT_INFO_RECEIVED: `${MESSAGE_NAME_PREFIX}/enrollment-info`,
  RETURNING_USER_INFO_RECEIVED: `${MESSAGE_NAME_PREFIX}/returning-user-info`,
  RESIZE_CONTAINER: `${MESSAGE_NAME_PREFIX}/resize-container`,
  REQUEST_LOOKUP_DATA: `${MESSAGE_NAME_PREFIX}/request-lookup-data`,
  GO_TO_ENROLLMENT: `${MESSAGE_NAME_PREFIX}/go-to-enrollment`,
  CREATE_ORDER: `${MESSAGE_NAME_PREFIX}/create-order`,
  REQUEST_DEVICE_ID: `${MESSAGE_NAME_PREFIX}/request-device-id`,
  REQUEST_CUSTOMER_EXISTS: `${MESSAGE_NAME_PREFIX}/request-customer-exists`,
  LOOKUP_BY_FINGERPRINT: `${MESSAGE_NAME_PREFIX}/lookup-by-fingerprint`,
  LAYER_NO_ANCHOR_FOUND: `${MESSAGE_NAME_PREFIX}/layer-no-anchor-found`,
} as const;

export const LISTENER_IDS = {
  LOOKUP: "lookup",
} as const;

export const UNINITIALIZED_CALL = "UNINITIALIZED_CALL";
