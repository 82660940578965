import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { Footer, FooterV2 } from "@components/Footer";
import { Header, HeaderV2 } from "@components/Header";
import useOrderStore from "@stores/orderStore";
import Layout from "./Layout";
import { PaylinkLayout } from "./Paylink";
import useMerchantConfigStore from "@stores/merchantConfigStore";
import useLoadingStore from "@stores/loadingStore";
import useParentWindowComms from "@hooks/useParentWindowComms";
import InitialLoading, { InitialLoadingSkeleton } from "@components/InitialLoading";
import skipifyEvents from "@services/skipifyEvents";
import SimpleBar from "simplebar-react";
import { useFlags } from "@hooks/ldWithOverrides";
import InferRef from "@utils/InferRefType";
import SamsungDemoLoadingScreen from "@components/SamsungDemoLoadingScreen";
import useSkipifyLayer from "@hooks/useSkipifyLayer";

type RouteResponsiveLayoutProps = {
  children?: JSX.Element;
  passLoadingControl?: boolean;
  footerAdditionalContent?: ReactNode;
  footerSkipConfirmationOnExit?: boolean;
  hideCart?: boolean;
  showOrderSummary?: boolean;
};

type OverlayLoadingProps = {
  setOverlayLoadingVisible?: (v: boolean) => void;
  overlayLoadingVisible?: boolean;
};

type EmbedContext = {
  state: "Embed";
};

type PaylinkContext = {
  state: "Paylink";
  isPaylinkException: boolean;
};

type ErrorContext = {
  state: "Error";
  message: string;
};

type LoadingContext = {
  state: "Loading";
};

type RouteResponsiveContext = EmbedContext | PaylinkContext | ErrorContext | LoadingContext;

// Broadly, this layout wraps embed and paylink pages.
// It checks the dynamic route parameters and initializes the order and the merchant stores if they are needed.
// It also passes the Layout component the appropriate props after hydration, based on route.
// Note the enrolment flow will not have an order Id
// The layout has different responsibilites depending on the state it is in, as follows:
// Paylink State: Merchant Config, Order
// Embed State: Merchant Config, Order
// Loading State: Waiting for merchant config and order
// Error State: Close modal
const RouteResponsiveLayout = ({
  children,
  passLoadingControl,
  footerAdditionalContent,
  footerSkipConfirmationOnExit,
  hideCart = false,
  showOrderSummary = true,
}: RouteResponsiveLayoutProps) => {
  const router = useRouter();
  const isEmbed = router.pathname?.includes("/embed/");
  const isPaylink = router.pathname?.includes("/paylink/");
  const isLookup = router.pathname?.includes("/lookup");
  const isEnroll = router.pathname?.includes("/enroll");
  const isLogin = router.pathname?.includes("/login");
  const isPaylinkException = isPaylink && router.pathname?.includes("/exception");
  // used to trigger an unrecoverable error if the layout is used outside of /embed and /paylink routes
  const [routeResponsiveContext, setRouteResponsiveContext] = useState<RouteResponsiveContext>(
    isEmbed || isPaylink ? { state: "Loading" } : { state: "Error", message: "neither embed or paylink in route" },
  );
  const skipifyLayer = useSkipifyLayer();

  // State to control a loading state form children
  // When all vital data is loaded, children go invisible below the loading screen
  // and are expected to hide the loading screen manually
  const [overlayLoadingVisible, setOverlayLoadingVisible] = useState(Boolean(passLoadingControl));

  const { percentage, loadingError, incrementPercentage } = useLoadingStore();
  const [order, fetchOrder, orderAuthorizationError] = useOrderStore((state) => [
    state.order,
    state.fetch,
    state.fetchOrderError,
  ]);
  // need to use local state to track order loading because of the order that try, catch, finally resolves in orderStore when catch throws an error. The finally was setting loading back to false before the error was thrown.
  const [orderLoading, setOrderLoading] = useState(false);

  const [allowedDomains, merchantBranding, fetchMerchant, merchantId, merchantLoading] = useMerchantConfigStore(
    (state) => [state.allowedDomains, state.branding, state.fetch, state.merchantId, state.loading],
  );
  const { closeIframe, hideIframe, checkoutExitedBeforeOrderComplete } = useParentWindowComms({
    allowedDomains,
  });
  const flags = useFlags();

  const orderCompletePath = router.pathname?.includes("ordercomplete");
  const scrollRef = useRef<InferRef<typeof SimpleBar>>(null);

  useEffect(() => {
    const exec = async () => {
      // When order id is available from the route and the order in the store is empty or has a different order.id -> fetch the order
      const routeOrderId = router.query?.oid as string;
      if (routeOrderId && routeOrderId !== order?.id && !orderAuthorizationError) {
        setOrderLoading(true);
        try {
          await fetchOrder(routeOrderId);
          incrementPercentage(33);
        } catch (e) {
          // 401 and 403 errors are swallowed by the fetchOrder function
          // 401 and 403 on fetch order do not mean we should prevent the user from continuing. A user may be trying to /get an order that is claimed
          // we need to authenticate them to see if they are the user that claimed the order.
          setRouteResponsiveContext({ state: "Error", message: (e as Error)?.message || "no message available" });
        } finally {
          setOrderLoading(false);
        }
      } else {
        // Order is not needed, so just move the loader
        incrementPercentage(33);
      }
    };

    if (router.isReady && !orderLoading && routeResponsiveContext.state !== "Error") {
      exec();
    }
  }, [
    fetchOrder,
    incrementPercentage,
    order?.id,
    orderAuthorizationError,
    router.isReady,
    router.query?.oid,
    orderLoading,
    routeResponsiveContext.state,
  ]);

  useEffect(() => {
    const exec = async () => {
      // When merchant id is available from the route then initialize the store then set allowedDomains
      const routeMerchantId = router.query?.mid as string;
      if (routeMerchantId !== merchantId) {
        try {
          await fetchMerchant(routeMerchantId);
          incrementPercentage(33);
        } catch (e) {
          setRouteResponsiveContext({ state: "Error", message: (e as Error)?.message || "no message available" });
        } finally {
          skipifyEvents.identify();
        }
      }
    };
    if (router.isReady && !merchantLoading && routeResponsiveContext.state !== "Error") {
      exec();
    }
  }, [
    fetchMerchant,
    incrementPercentage,
    merchantId,
    router.isReady,
    router.query?.mid,
    merchantLoading,
    routeResponsiveContext.state,
  ]);

  // Once we have fetched merchant and order information (is applicable) set context to move into the flow
  useEffect(() => {
    // If a paylink exception occured we won't necessarily have an order.Id or an orderAuthorization error but we should still move to the paylink layout.
    if (isPaylinkException) {
      setRouteResponsiveContext({ state: "Paylink", isPaylinkException });
    }
    if (isLookup) {
      isEmbed
        ? setRouteResponsiveContext({ state: "Embed" })
        : setRouteResponsiveContext({ state: "Paylink", isPaylinkException });
    }
    if (isEnroll) {
      isEmbed
        ? setRouteResponsiveContext({ state: "Embed" })
        : setRouteResponsiveContext({ state: "Paylink", isPaylinkException });
    }

    // A case for the separate login screen for the checkout flow
    // that does not need an order directly but creates it by itself
    if (isLogin && isEmbed && !router.query?.oid) {
      setRouteResponsiveContext({ state: "Embed" });
    }

    if ((order?.id || orderAuthorizationError) && merchantId) {
      if (isEmbed) {
        setRouteResponsiveContext({ state: "Embed" });
      }
      if (isPaylink) {
        setRouteResponsiveContext({ state: "Paylink", isPaylinkException });
      }
    }
  }, [
    isEmbed,
    isPaylink,
    isPaylinkException,
    merchantId,
    order?.id,
    orderAuthorizationError,
    isLookup,
    isEnroll,
    isLogin,
    router.query?.oid,
  ]);

  const onExit = useCallback(async () => {
    await skipifyEvents.onExit();
    // we only want to send this message if user did not complete order
    if (!orderCompletePath) {
      checkoutExitedBeforeOrderComplete();
    }
    hideIframe();
  }, [hideIframe, checkoutExitedBeforeOrderComplete, orderCompletePath]);

  // Handles an initialization or loading error which may be set in store by a child component and closes the modal
  useEffect(() => {
    if (loadingError) {
      setTimeout(closeIframe, 5000);
    }
  }, [closeIframe, loadingError]);

  // This returns the appropriate loading component based on route and LD flag.
  const loadingComponent = useCallback(() => {
    if (flags.samsungDemo && isEmbed && (isLogin || isLookup)) {
      return <SamsungDemoLoadingScreen />;
    }

    if (skipifyLayer && isEmbed) {
      return (
        <Layout footer={<FooterV2 dataTestid="footer" />} header={<HeaderV2 onExit={onExit}></HeaderV2>}>
          <InitialLoadingSkeleton />
        </Layout>
      );
    }

    return <InitialLoading progress={percentage} error={loadingError} />;
  }, [flags.samsungDemo, skipifyLayer, isEmbed, isLogin, isLookup, loadingError, onExit, percentage]);

  // Adds a loading component on top of the content
  // if we want to pass a loading state control down.
  const renderWithOverlayLoading = useCallback(
    (content: JSX.Element) => {
      return (
        <>
          {/* InitialLoading overlays the entire screen with a fixed-position splash screen */}
          {/* The content will still be rendered but blocked visually */}
          {overlayLoadingVisible && loadingComponent()}
          {content}
        </>
      );
    },
    [loadingComponent, overlayLoadingVisible],
  );

  // Adds a setOverlayLoadingVisible prop to children if necessary
  const renderWithPassLoadingStateControl = useCallback(
    (content: JSX.Element | undefined) => {
      if (passLoadingControl) {
        return (
          <>
            {React.Children.map(children, (child: React.ReactNode) => {
              if (React.isValidElement<OverlayLoadingProps>(child)) {
                return React.cloneElement(child, {
                  setOverlayLoadingVisible,
                  overlayLoadingVisible,
                });
              }
              return child;
            })}
          </>
        );
      }
      return content;
    },
    [children, overlayLoadingVisible, passLoadingControl],
  );

  // This sections handles what the route responsive layout returns depending on context state
  switch (routeResponsiveContext.state) {
    case "Loading":
      return loadingComponent();
    case "Embed":
      return renderWithOverlayLoading(
        <SimpleBar autoHide={false} style={{ height: "100%" }} ref={scrollRef}>
          <Layout
            order={order}
            showOrderSummary={showOrderSummary}
            footer={
              skipifyLayer ? (
                <FooterV2 dataTestid="footer">{footerAdditionalContent}</FooterV2>
              ) : (
                <Footer onExit={onExit} dataTestid="footer" skipConfirmationOnExit={footerSkipConfirmationOnExit}>
                  {footerAdditionalContent}
                </Footer>
              )
            }
            header={
              skipifyLayer ? (
                <HeaderV2 onExit={onExit}></HeaderV2>
              ) : (
                <Header storeLogo={merchantBranding?.logoSrc} order={order} scrollRef={scrollRef} />
              )
            }
          >
            {renderWithPassLoadingStateControl(children)}
          </Layout>
        </SimpleBar>,
      );
    case "Paylink":
      if (routeResponsiveContext.isPaylinkException) {
        return <PaylinkLayout header={<Header storeLogo={merchantBranding?.logoSrc} />}>{children}</PaylinkLayout>;
      }
      return renderWithOverlayLoading(
        <PaylinkLayout
          order={order}
          footer={
            <Footer customItemMd={0} dataTestid="footer">
              {footerAdditionalContent}
            </Footer>
          }
          header={
            <Header
              storeLogo={merchantBranding?.logoSrc}
              order={order}
              showCartOnDesktop
              hideCart={hideCart}
              paylinkDesktopOverride
            />
          }
        >
          {renderWithPassLoadingStateControl(children)}
        </PaylinkLayout>,
      );
    default:
      return <InitialLoading error={routeResponsiveContext.message} />;
  }
};

export default RouteResponsiveLayout;
